"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppContainer = exports.GlobalStyles = void 0;
const styled_components_1 = require("styled-components");
exports.GlobalStyles = (0, styled_components_1.createGlobalStyle) `
  html, body, div#root {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  & *::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  & *::-webkit-scrollbar-thumb {
    background-color: #dddddd;
  }

  @font-face {
    font-family: "Catallina";
    src: url("/assets/Catallina2.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: normal; 
  } 
  
  @media screen and (max-width: 480px) {
    & *::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }
`;
exports.AppContainer = styled_components_1.default.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  font-family: Raleway;
  font-style: normal;
  color: #000;
`;
